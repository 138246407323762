







































































































































// @ is an alias to /src
import { MutationPayload } from 'vuex';
import { EventClickArg } from '@fullcalendar/vue';
import store from '@/store';
import Loading from '@/components/loading.vue';
import router from '@/router';
import { ISession } from '@/models/session';
import { ISessionsState } from '@/store/modules/sessions';
import SessionCard from '@/partials/SessionCard.vue';
import { getExhibitorSessions } from '@/api/sessions';
import { getExhibitorSessionLimits } from '@/api/exhibitors';
import { computed, defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  components: { Loading, SessionCard },
  data() {
    return {
      daysOfSessions: [] as Date[],
      openDay: 0,
      event: 0,
      exhibitor: 0,
      upcomingSessions: [] as ISession[],
      firstLoad: true,
      limit: 9999,
    };
  },
  setup() {
    const { translations, language } = useContext();
    const sessions = computed(() => store.getters.getSessions as ISession[]);
    const sessionsState = computed(() => store.getters.getSessionsState as ISessionsState[]);
    return { translations, language, sessions, sessionsState };
  },
  async created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    const currentUser = store.getters.currentUser;

    if (currentUser.id) {
      this.getEventSessions();
    }
    const response = await getExhibitorSessionLimits({ exhibitor: this.exhibitor });
    const exhibitor = response.data.results[0];
    if (exhibitor) {
      this.limit = exhibitor.limit;
    }
    const unsubscribe = store.subscribe((mutation) => {
      if (mutation.type === 'setUserLogin') {
        this.getEventSessions();
        unsubscribe();
      }
    });
  },
  methods: {
    filterSessions(session: ISession, sessionDay: Date) {
      const sessionDate = new Date(session.start_date);
      return sessionDate.getDate() === sessionDay.getDate();
    },
    getTime(date: string) {
      const newDate = new Date(date);
      return newDate.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    },

    getEventSessions() {
      store.dispatch('fetchSessions', {
        event: this.event,
        type: 'exhibitor',
        exhibitor: this.exhibitor,
        translations: true,
        page: 1,
        page_size: 1000,
        reset: true,
      });
      if (this.firstLoad) {
        const today = new Date();
        const todayString = `${today.toISOString().split('.')[0]}Z`;
        getExhibitorSessions({
          event: this.event,
          exhibitor: this.exhibitor,
          page: 1,
          page_size: 5,
          end_date_gte: todayString,
          reset: true,
        }).then((response) => {
          this.upcomingSessions = response.data.results.filter(
            (session) => session.name !== 'check-in' && session.name !== 'checkin',
          );
        });
      }

      this.firstLoad = false;

      store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setSessions') {
          this.daysOfSessions = [];
          const newDates = [] as string[];
          this.sessions.forEach((session) => {
            const newDateString = session.start_date.split('T')[0];
            const newDate = new Date(newDateString);
            if (!newDates.includes(newDateString)) {
              this.daysOfSessions.push(newDate);
              newDates.push(newDateString);
            }
          });
          this.daysOfSessions.sort((a, b) => a.getTime() - b.getTime());
        }
      });
    },

    toggleDay(i: number) {
      this.openDay = i;
      this.$forceUpdate();
    },

    selectSession(sessionInfo: EventClickArg) {
      const sessionId = parseInt(sessionInfo.event.id, 10);
      router.push(
        `/event/${this.event}/manage-exhibitor/${store.getters.getContextExhibitor.id}/sessions/edit/${sessionId}`,
      );
    },
  },
});
